import { render } from 'react-dom';
import React, { useState, useEffect } from 'react';
import App from './App';

const Main = () => {

    /**=========
     * STATES
    ============*/
    const [loading, setLoading] = useState(true);
    const [darkMode, setDarkMode] = useState(() => {

        // READ DARKMODE VARIABLE FROM LOCALSTORAGE WHEN INITIALIZING STATE //
        const darkModeSetting = localStorage.getItem('darkMode');

        return darkModeSetting === 'true';

    });

    useEffect(() => {

        // SIMULATE AN INITIALIZATION LOAD OF YOUR APPLICATION //
        setTimeout(() => {

            setLoading(false);

        }, 1000); // HERE YOU CAN ADJUST THE TIME OR USE A REAL PROMISE //

    }, []);

    if (loading) {

        return (
            
            <div
                style={{
                    height: '100%',
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: darkMode ? '#141414' : 'white', // APPLY BACKGROUND ACCORDING TO DARKMODE //
                }}
            >

                <p style={{ fontSize: '24px', color: darkMode ? 'rgba(255, 255, 255, 0.65)' : 'rgba(0, 0, 0, 0.65)' }}>
                    Please wait a moment.
                </p>

            </div>

        );
        
    }

    return <App />;
    
};

render(<Main />, document.getElementById('root')!);