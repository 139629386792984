import axios from 'axios';
import { envs } from '../config/envs';

const emailBuilderApi = axios.create({
    baseURL: envs.apiUrl,
    headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
    }
})

export default emailBuilderApi;