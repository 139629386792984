import './LoginForm.css';
import { LoginResponse } from './login_response.interface';
import { useAuth } from '../../../contexts/AuthContext';
import { useHistory } from 'react-router-dom';
import emailBuilderApi from '../../../../api/emailBuilderApi';
import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';

export const LoginForm = (): JSX.Element => {

  const history = useHistory();
  const { login } = useAuth();

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSubmit = async (e: React.FormEvent): Promise<void> => {
    
    e.preventDefault();

    if (email.trim() !== '' && password.trim() !== '') {

      setIsLoading(true);

      try {

        const { data } = await emailBuilderApi.post<LoginResponse>('auth', { email, password });

        if (data) {

          localStorage.setItem('token', data.data.token);
          login();

          Swal.fire({
            customClass: 'dark:bg-[#141414]',
            position: "center",
            icon: "success",
            title: "",
            text: "The user logged in correctly",
            showConfirmButton: false,
            timer: 1500,
            timerProgressBar: true,
          });

          setTimeout(() => {
            window.location.replace('/');
          }, 1500);

        }

      } catch (error) {

        Swal.fire({
          customClass: 'dark:bg-[#141414]',
          icon: "error",
          title: "",
          text: "Error to authenticate",
          timer: 2000,
          timerProgressBar: true,
        });

        console.error('Error to authenticate:', error);

      } finally {

        setIsLoading(false);

      }

    } else {

      Swal.fire({
        customClass: 'dark:bg-[#141414]',
        icon: "error",
        title: "",
        text: "Please complete the email and password",
        timer: 2000,
        timerProgressBar: true,
      });

    }

  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    (token) && history.push('/');
  }, [])


  return (

    /**=============
     * LOGIN FORM
    ================*/
    <div className="login-form">

      {/*=========
          TITLE
      ============*/}
      <h1 className="text-2xl font-bold dark:text-white">Login</h1>

      {/*========
          FORM
      ===========*/}
      <form onSubmit={handleSubmit}>

        {/*===============
            EMAIL INPUT
        ==================*/}
        <div className="form-group">
          <label htmlFor="email" className="dark:text-white">Email</label>
          <input
            className="dark:bg-[#141414] text-[#cacaca]"
            type="email"
            id="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Type your email"
            required
            disabled={isLoading}
          />
        </div>

        {/*==================
            PASSWORD INPUT
        =====================*/}
        <div className="form-group">
          <label htmlFor="password" className="dark:text-white">Password</label>
          <input
            className="dark:bg-[#141414] text-[#cacaca]"
            type="password"
            id="password"
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Type your password"
            required
            disabled={isLoading}
          />
        </div>

        {/*================
            LOGIN BUTTON
        ===================*/}
        <button type="submit" className="login-button transition duration-300 ease-in-out" disabled={isLoading}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            style={{ width: '20px', height: '20px', marginRight: '8px', fill: 'white' }}
          >
            <path d="M217.9 105.9L340.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L217.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1L32 320c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM352 416l64 0c17.7 0 32-14.3 32-32l0-256c0-17.7-14.3-32-32-32l-64 0c-17.7 0-32-14.3-32-32s14.3-32 32-32l64 0c53 0 96 43 96 96l0 256c0 53-43 96-96 96l-64 0c-17.7 0-32-14.3-32-32s14.3-32 32-32z" />
          </svg>
          <span>Login</span>
        </button>

      </form>

    </div>

  );

};