
/**=====================================
 * AUXILIARY FUNCTION TO FORMAT DATES
 * @param {number} timestamp 
 * @returns {string}
========================================*/
export const formatDate = (timestamp: number): string => {
    
    const date = new Date(timestamp);

    // SET FORMATTING OPTIONS //
    const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'long',
        day: '2-digit',
        hour: 'numeric',
        minute: 'numeric',
        hour12: false
    };

    // FORMAT THE DATE //
    return date.toLocaleString('en-US', options);
    
}
