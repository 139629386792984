import '../Frame/index.css';
import { githubButtonGenerate } from '@demo/utils/githubButtonGenerate';
import { Layout, Menu, Breadcrumb, Button, Modal, Popover, Tree } from '@arco-design/web-react';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { Stack } from '../Stack';
import { useAuth } from '../../contexts/AuthContext';
import emailBuilderApi from '../../../api/emailBuilderApi';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Swal from 'sweetalert2';
import { pushEvent } from '../../utils/pushEvent';

// const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;

interface FrameProps {
  title: string;
  breadcrumb?: React.ReactElement;
  primaryAction?: React.ReactElement;
  children: React.ReactElement;
}

interface ListResponse {
  data: ListResponseData[];
  status: number;
}

interface ListResponseData {
  rec_id: number;
  slug: string;
  label: string;
}

interface Label {
  column_name: string;
  title: string;
}

export interface FieldsResponse {
  data: FieldsData[];
  status: number;
}

export interface FieldsData {
  label: string;
  column_name: string;
  type: string;
}

interface TemplateListData {
  list_id: number;
  subject: string;
  template_id: number;
  template_name: string;
  type: string;
  module: string;
  creator_name: string;
  modifier_name: string;
  status: string;
  created_at: string;
  updated_at: string;
}

export default function Frame({ children, title, primaryAction, breadcrumb }: FrameProps) {

  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const moduleSlug = queryParams.get('slug');

  const { logout } = useAuth(); // USE THE HOOK USEAUTH TO OBTAIN THE LOGOUT METHOD

  /**=========
   * STATES
  ============*/
  const [visible, setVisible] = useState<boolean>(false); // TO SEE THE ADD MODAL //
  const [list, setList] = useState<ListResponseData[]>();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [titleInput, setTitle] = useState<string>(''); // TITLE INPUT VALUE //
  const [subject, setSubject] = useState<string>(''); // SUBJECT INPUT VALUE //
  const [selectedOption, setSelectedOption] = useState(''); // MODULE INPUT VALUE //
  const [fetchLabelsData, setFetchLabelsData] = useState<Label[]>([]);
  const [popoverVisible, setPopoverVisible] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState('');
  const [expandedKeys, setExpandedKeys] = useState<string[]>([]);
  const [isDarkMode, setIsDarkMode] = useState(() => {
    const savedMode = localStorage.getItem('darkMode');
    return savedMode ? JSON.parse(savedMode) : false;
  });

  /**=============
   * REFERENCES
  ================*/
  const dropdownRef = useRef<HTMLDivElement>(null);
  const titleRef = useRef<HTMLInputElement>(null);
  const subjectRef = useRef<HTMLDivElement>(null);
  const moduleRef = useRef<HTMLSelectElement>(null);
  const mergeTagSearchInput = useRef<HTMLInputElement>(null);

  /**============================
   * TOGGLE DARK MODE FUNCTION
   * @returns {void}
  ===============================*/
  const toggleDarkMode = (): void => {
    const newMode = !isDarkMode;
    setIsDarkMode(newMode);
    localStorage.setItem('darkMode', JSON.stringify(newMode));
  };

  /**===========================
   * TOGGLE DROPDOWN FUNCTION
   * @returns {void}
  ==============================*/
  const toggleDropdown = (): void => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  /**===========================
   * LOGOUT BUTTON FUNCTION
   * @returns {Promise<void>}
  ==============================*/
  const logoutBtn = async (): Promise<void> => {

    const { data } = await emailBuilderApi.post('logout');

    if (data) {

      logout(); // CALL THE AUTHENTICATION CONTEXT LOGOUT //

      setIsDropdownOpen(false); // Cerrar el dropdown al hacer clic en logout

      history.push('/login'); // REDIRECT THE USER TO THE LOGIN PAGE //

      Swal.fire({
        customClass: 'dark:bg-[#141414]',
        position: "center",
        icon: "success",
        title: "",
        text: "The user logged out successfully",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
      });

    }

  };

  /**===========================
   * GET LIST FUNCTION
   * @returns {Promise<void>}
  ==============================*/
  const getList = async (): Promise<void> => {
    try {
      const { data } = await emailBuilderApi.get<ListResponse>('modules/list');
      if (data) {
        setList(data.data);
      }
    } catch (error) {
      console.error('Error fetching list', error);
    }
  }

  /**===========================
   * GET LABELS FUNCTION
   * @returns {Promise<void>}
  ==============================*/
  const getLabels = async (): Promise<void> => {

    try {

      const { data } = await emailBuilderApi.get<FieldsResponse>(`modules/${moduleSlug ?? 'leads'}/fields`);

      const labelFormat: Label[] = data.data.map(ele => {
        return {
          column_name: ele.column_name,
          title: ele.label
        }
      });

      setFetchLabelsData(labelFormat);

    } catch (error: any) {

      Swal.fire({
        customClass: 'dark:bg-[#141414]',
        icon: "error",
        title: "Oops...",
        toast: false,
        position: 'center',
        timer: 3000,
        timerProgressBar: true,
        text: "No Fields were found",
      });

      console.log(error.response.data);

    }

  }

  /**========================
   * GO TO EDITOR FUNCTION
   * @returns {void}
  ===========================*/
  const goToEditor = (): void => {

    if (selectedOption != '' && titleInput != '' && subject != '') {

      // SUPPOSE 'SUBJECT' IS THE ORIGINAL STRING CONTAINING THE INPUTS AND '&NBSP;' //
      let modifiedSubject = subject; // WE MAKE A COPY OF 'SUBJECT' TO MODIFY //

      // SPLIT ORIGINAL STRING USING '&NBSP;' //
      const parts = subject.split('&nbsp;');

      // ITERATE OVER PARTS TO FIND AND REPLACE INPUTS WITH 'EASY-EMAIL-MERGE-TAG' CLASS //
      parts.forEach(part => {

        if (part.includes('class="easy-email-merge-tag"')) {

          // FIND THE VALUE OF THE 'VALUE' ATTRIBUTE WITHIN THE INPUT //
          const valueMatch = /value=['"]([^'"]*)['"]/i.exec(part);

          if (valueMatch) {

            const inputValue = valueMatch[1]; // VALUE WITHIN THE 'VALUE' ATTRIBUTE OF THE INPUT //

            // FIND THE OBJECT IN FETCHLABELSDATA THAT HAS THE SAME TITLE AS THE INPUT VALUE //
            const columnObj = fetchLabelsData.find(col => col.title === inputValue);

            if (columnObj) {

              // REPLACE THE FOUND INPUT WITH THE CORRESPONDING PLACEHOLDER //
              modifiedSubject = modifiedSubject.replace(part, `\${${columnObj.column_name}}`);

            }

          }

        }

      });

      // REPLACE '&NBSP;' FOR BLANK SPACES //
      // modifiedSubject = modifiedSubject.replace(/&nbsp;/g, ' ');

      localStorage.setItem('templateInfo', JSON.stringify({ title: titleInput, subject: modifiedSubject }));
      history.push(`/editor?moduleSlug=${selectedOption}`);

    } else {

      Swal.fire({
        customClass: 'dark:bg-[#141414]',
        icon: "error",
        title: "",
        text: "You must complete all empty fields",
        position: "top",
        toast: true,
        timer: 2000,
        timerProgressBar: true,
      });

      const fields = {
        titleInput: titleRef.current,
        subject: subjectRef.current,
        selectedOption: moduleRef.current,
      };

      for (const [key, ref] of Object.entries(fields)) {
        if (!eval(key)) {
          ref?.focus();
          break;
        }
      }

    }

  }

  /**======================
   * ON DISCARD FUNCTION
   * @returns {void}
  =========================*/
  const onDiscard = (): void => {
    setVisible(false);
    setSelectedOption('');
    setTitle('');
    setSubject('');
  };

  /**========================
   * HANDLE INPUT FUNCTION
   * @param {any} e
   * @returns {void}
  ===========================*/
  const handleInput = (e: any): void => {
    setSubject(e.target.innerHTML); // UPDATE SUBJECT STATUS
  };

  /**========================
   * HANDLE FOCUS FUNCTION
   * @returns {void}
  ===========================*/
  const handleFocus = (): void => {
    setTimeout(setCaretToEnd, 0);  // DELAY SETTING CARET TO THE END TO ENSURE IT HAPPENS AFTER FOCUS //
  };

  /**===========================
   * SET CARET TO END FUNTION
   * @returns {void}
  ==============================*/
  const setCaretToEnd = (): void => {
    const el = subjectRef.current;
    if (el) {
      const range = document.createRange();
      const sel = window.getSelection();
      range.selectNodeContents(el);
      range.collapse(false);
      sel?.removeAllRanges();
      sel?.addRange(range);
      el.focus();
    }
  };

  const filteredTreeOptions = useMemo(() => {

    const filterData = (data: any[], query: string) => {
      return data.filter(item => item.title.toLowerCase().includes(query.toLowerCase()));
    };

    const treeData = fetchLabelsData.map(label => ({
      key: label.column_name,
      value: label.column_name,
      title: label.title,
      children: []
    }));

    return filterData(treeData, searchValue);

  }, [fetchLabelsData, searchValue]);

  const onSelect = useCallback(

    (selectedKeys: any[]) => {

      const key = selectedKeys[0];

      // FIND THE OBJECT IN FETCHLABELSDATA THAT HAS COLUMN_NAME EQUAL TO KEY //
      const selectedLabel = fetchLabelsData.find(label => label.column_name === key);

      if (selectedLabel) {

        const updatedSubject = `${subject}&nbsp;<input class="easy-email-merge-tag" type="button" value='${selectedLabel.title}'>&nbsp;`;
        setSubject(updatedSubject.trim());

      } else {

        // HANDLE THE CASE WHERE THE CORRESPONDING LABEL IS NOT FOUND //
        console.error(`No se encontró el label para column_name: ${key}`);

      }

      setPopoverVisible(false);

    }, [subject, fetchLabelsData]

  );

  /**==============
   * USE EFFECTS
  =================*/
  useEffect(() => {

    const fetchData = async () => {

      const { data } = await emailBuilderApi.get<FieldsResponse>(`modules/${selectedOption}/fields`);

      const labelFormat: Label[] = data.data.map(ele => {
        return {
          column_name: ele.column_name,
          title: ele.label
        }
      });

      setFetchLabelsData(labelFormat);

    };

    if (selectedOption) {
      fetchData();
    }

  }, [selectedOption]);

  useEffect(() => {
    githubButtonGenerate();
  }, []);

  useEffect(() => {
    getList();
  }, []);

  useEffect(() => {
    getLabels();
  }, [moduleSlug]);

  useEffect(() => {
    if (moduleSlug != null) {
      setSelectedOption(moduleSlug);
    }
  }, [moduleSlug]);

  useEffect(() => {
    if (visible === true) {
      getLabels();
      getList();
      document.body.style.overflow = '';
      document.body.style.width = '';
    }
  }, [visible]);

  useEffect(() => {

    const handleClickOutside = (event: any) => {

      // CLOSE THE DROPDOWN MENU ONLY IF YOU CLICK OUTSIDE THE DROPDOWN MENU AND NOT ON THE USERNAME OR EMAIL //
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        event.target.tagName.toLowerCase() !== 'button' // DO NOT CLOSE IF A BUTTON IS CLICKED (E.G. LOGOUT) //
      ) {

        setIsDropdownOpen(false);

      }

    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };

  }, []);

  useEffect(() => {
    setCaretToEnd();
  }, [subject]);

  /**=====================================================================
   * APPLY THE DARK CLASS TO THE HTML ELEMENT BASED ON ISDARKMODE STATE
  ========================================================================*/
  useEffect(() => {
    if (isDarkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [isDarkMode]);

  return (

    <Layout>

      {/*----------
          HEADER
      -------------*/}
      <Header className="px-10 text-black dark:bg-[#141414] dark:text-white">

        <Stack distribution='equalSpacing' alignment='center'>

          {/*--------
              LOGO
          -----------*/}
          <h1 className='text-xl dark:text-white my-[15px] flex items-center font-bold'>

            {/* ICON */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              className='w-[40px] h-[40px] dark:fill-white mr-[8px]'
            >
              <path d="M64 112c-8.8 0-16 7.2-16 16v22.1L220.5 291.7c20.7 17 50.4 17 71.1 0L464 150.1V128c0-8.8-7.2-16-16-16H64zM48 212.2V384c0 8.8 7.2 16 16 16H448c8.8 0 16-7.2 16-16V212.2L322 328.8c-38.4 31.5-93.7 31.5-132 0L48 212.2zM0 128C0 92.7 28.7 64 64 64H448c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128z" />
            </svg>

            {/* TEXT */}
            <span>Gizmo Email Templates</span>

          </h1>

          {/*======================================
              SWITCH, USERNAME, EMAIL AND AVATAR
          =========================================*/}
          <section className="flex items-center my-[15px]">

            {/*==========
                SWITCH
            =============*/}
            <div className="mr-8">

              {/*===================================
                    DARK MODE AND LIGHT MODE SWITCH
                ======================================*/}
              <label className="inline-flex items-center cursor-pointer">

                {/* INPUT */}
                <input
                  type="checkbox"
                  checked={isDarkMode}
                  onChange={toggleDarkMode}
                  className="sr-only peer"
                />

                {/* ICONS */}
                <div className="relative w-14 h-8 bg-yellow-400 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-5 rtl:peer-checked:after:-translate-x-7 peer-checked:after:border-white after:content-[''] after:absolute after:top-[7px] after:start-2 after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600">

                  {/* SUN ICON */}
                  <svg
                    className="absolute right-2 top-2 h-4 w-4 text-white dark:text-gray-400"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <path d="M375.7 19.7c-1.5-8-6.9-14.7-14.4-17.8s-16.1-2.2-22.8 2.4L256 61.1 173.5 4.2c-6.7-4.6-15.3-5.5-22.8-2.4s-12.9 9.8-14.4 17.8l-18.1 98.5L19.7 136.3c-8 1.5-14.7 6.9-17.8 14.4s-2.2 16.1 2.4 22.8L61.1 256 4.2 338.5c-4.6 6.7-5.5 15.3-2.4 22.8s9.8 13 17.8 14.4l98.5 18.1 18.1 98.5c1.5 8 6.9 14.7 14.4 17.8s16.1 2.2 22.8-2.4L256 450.9l82.5 56.9c6.7 4.6 15.3 5.5 22.8 2.4s12.9-9.8 14.4-17.8l18.1-98.5 98.5-18.1c8-1.5 14.7-6.9 17.8-14.4s2.2-16.1-2.4-22.8L450.9 256l56.9-82.5c4.6-6.7 5.5-15.3 2.4-22.8s-9.8-12.9-17.8-14.4l-98.5-18.1L375.7 19.7zM269.6 110l65.6-45.2 14.4 78.3c1.8 9.8 9.5 17.5 19.3 19.3l78.3 14.4L402 242.4c-5.7 8.2-5.7 19 0 27.2l45.2 65.6-78.3 14.4c-9.8 1.8-17.5 9.5-19.3 19.3l-14.4 78.3L269.6 402c-8.2-5.7-19-5.7-27.2 0l-65.6 45.2-14.4-78.3c-1.8-9.8-9.5-17.5-19.3-19.3L64.8 335.2 110 269.6c5.7-8.2 5.7-19 0-27.2L64.8 176.8l78.3-14.4c9.8-1.8 17.5-9.5 19.3-19.3l14.4-78.3L242.4 110c8.2 5.7 19 5.7 27.2 0zM256 368a112 112 0 1 0 0-224 112 112 0 1 0 0 224zM192 256a64 64 0 1 1 128 0 64 64 0 1 1 -128 0z" />
                  </svg>

                  {/* MOON ICON */}
                  <svg
                    className="absolute left-2 top-2 h-4 w-4 text-gray-400 dark:text-yellow-500"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 384 512"
                  >
                    <path d="M144.7 98.7c-21 34.1-33.1 74.3-33.1 117.3c0 98 62.8 181.4 150.4 211.7c-12.4 2.8-25.3 4.3-38.6 4.3C126.6 432 48 353.3 48 256c0-68.9 39.4-128.4 96.8-157.3zm62.1-66C91.1 41.2 0 137.9 0 256C0 379.7 100 480 223.5 480c47.8 0 92-15 128.4-40.6c1.9-1.3 3.7-2.7 5.5-4c4.8-3.6 9.4-7.4 13.9-11.4c2.7-2.4 5.3-4.8 7.9-7.3c5-4.9 6.3-12.5 3.1-18.7s-10.1-9.7-17-8.5c-3.7 .6-7.4 1.2-11.1 1.6c-5 .5-10.1 .9-15.3 1c-1.2 0-2.5 0-3.7 0c-.1 0-.2 0-.3 0c-96.8-.2-175.2-78.9-175.2-176c0-54.8 24.9-103.7 64.1-136c1-.9 2.1-1.7 3.2-2.6c4-3.2 8.2-6.2 12.5-9c3.1-2 6.3-4 9.6-5.8c6.1-3.5 9.2-10.5 7.7-17.3s-7.3-11.9-14.3-12.5c-3.6-.3-7.1-.5-10.7-.6c-2.7-.1-5.5-.1-8.2-.1c-3.3 0-6.5 .1-9.8 .2c-2.3 .1-4.6 .2-6.9 .4z" />
                  </svg>

                </div>

              </label>

            </div>

            {/*==============================
                USERNAME, EMAIL AND AVATAR
            =================================*/}
            <div className="flex items-center cursor-pointer" onClick={toggleDropdown} ref={dropdownRef}>

              {/*======================
                  USERNAME AND EMAIL
              =========================*/}
              <div className="mr-4 text-right">
                <div className="text-base dark:text-white">Username</div>
                <small className="text-orange-500">email@example.com</small>
              </div>

              {/*=======================
                  AVATAR AND DROPDOWN
              ==========================*/}
              <div className="relative flex-shrink-0">

                {/* IMAGE */}
                <img
                  className="h-14 w-14 rounded-full"
                  src="https://via.placeholder.com/150"
                  alt="Avatar"
                />

                {/* DROPDOWN OPTIONS */}
                {isDropdownOpen && (
                  <div className="absolute right-0 mt-2 w-48 bg-gray-100 dark:bg-gray-800 rounded-md shadow-lg py-2 z-50">
                    <button
                      className="flex items-center px-4 py-2 text-sm w-full text-left transition duration-300 ease-in-out hover:bg-gray-200 dark:hover:bg-gray-600 hover:text-gray-900 dark:text-white"
                      onClick={logoutBtn}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        className="w-[20px] h-[20px] mr-[8px] dark:fill-white"
                      >
                        <path d="M377.9 105.9L500.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L377.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1-128 0c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM160 96L96 96c-17.7 0-32 14.3-32 32l0 256c0 17.7 14.3 32 32 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-64 0c-53 0-96-43-96-96L0 128C0 75 43 32 96 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32z" />
                      </svg>
                      <span>Logout</span>
                    </button>
                  </div>
                )}

              </div>

            </div>

          </section>

        </Stack>

      </Header>

      {/*---------------
          MAIN LAYOUT
      ------------------*/}
      <Layout>

        {/*-----------
            SIDEBAR
        --------------*/}
        <Sider width={250}>

          <Menu
            defaultSelectedKeys={['1']}
            defaultOpenKeys={['sub1']}
            className="h-[100%] border-r-0 dark:text-white dark:bg-[#141414]"
          >

            <div
              className="overflow-y-auto h-[700px]"
            >

              {/*=============
                  DASHBOARD
              ================*/}
              <NavLink
                className="rounded-full"
                key='dashboard'
                to={`/`}
              >
                <div className={`rounded-[20px] mb-[7px] overflow-hidden whitespace-nowrap text-ellipsis flex items-center p-2 ${(moduleSlug === null) ? 'bg-[#008000] text-white' : 'dark:bg-[#363635] dark:text-white'} transition duration-300 ease-in-out cursor-pointer hover:bg-[#008000] hover:text-white dark:hover:bg-[#008000] dark:hover:text-white`}>
                  {(moduleSlug === null) && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      className="w-[20px] h-[20px] fill-white ml-[8px]"
                    >
                      <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
                    </svg>
                  )}
                  <span className="ml-[8px]">Dashboard</span>
                </div>
              </NavLink>

              {
                list?.map(data => (
                  <NavLink
                    key={data.slug}
                    to={`/?slug=${data.slug}&label=${data.label}`}
                  >
                    <div className={`rounded-[20px] mb-[7px] overflow-hidden whitespace-nowrap text-ellipsis flex items-center p-2 ${(data.slug === moduleSlug) ? 'bg-[#008000] text-white' : 'dark:bg-[#363635] dark:text-white'} transition duration-300 ease-in-out cursor-pointer hover:bg-[#008000] hover:text-white dark:hover:bg-[#008000] dark:hover:text-white`}>
                      {(data.slug === moduleSlug) && (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                          className="w-[20px] h-[20px] fill-white ml-[8px]"
                        >
                          <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
                        </svg>
                      )}
                      <span className="ml-[8px]">{data.label}</span>
                    </div>
                  </NavLink>
                ))
              }

            </div>

          </Menu>

        </Sider>

        {/*------------------
            LAYOUT CONTENT
        ---------------------*/}
        <Layout>

          <section className="dark:bg-[#141414]">

            <Stack vertical>

              {breadcrumb && (
                <Breadcrumb>
                  <Breadcrumb.Item>{breadcrumb}</Breadcrumb.Item>
                </Breadcrumb>
              )}

              <Stack.Item>

                <section className="relative">

                  {/*===============================
                      CREATE A NEW TEMPLATE MODAL
                  ==================================*/}
                  <Modal
                    className="dark:bg-[#141414]"
                    title={
                      <p className="dark:text-white">Create a new template</p>
                    }
                    visible={Boolean(visible)}
                    onOk={goToEditor}
                    okText='New template'
                    cancelText='Discard'
                    onCancel={onDiscard}
                    style={{ zIndex: 10000 }}
                    footer={
                      <>
                        <Button onClick={onDiscard}>Discard</Button>
                        <Button type="primary" onClick={goToEditor}>New template</Button>
                      </>
                    }
                  >

                    {/*==========
                        MODULE
                    =============*/}
                    <div className="flex items-center mb-[8px] dark:text-white">

                      {/* LABEL */}
                      <label className="w-[55px] mr-[8px] dark:text-white">
                        Module:
                      </label>

                      {/* INPUT */}
                      <select
                        className="form-control dark:bg-[#141414] dark:text-[#cacaca]"
                        ref={moduleRef}
                        value={selectedOption}
                        onChange={(e) => setSelectedOption(e.target.value)}
                        style={{ flex: 1 }}
                      >
                        <option value="" disabled>Select a module</option>
                        {list?.map(item => (
                          <option key={item.rec_id} value={item.slug}>{item.label}</option>
                        ))}
                      </select>

                    </div>

                    {/*=========
                        TITLE
                    ============*/}
                    {selectedOption && (
                      <div className="flex items-center mb-[5px] dark:text-white">

                        {/* LABEL */}
                        <label className="w-[55px] mr-[8px] dark:text-white">
                          Title:
                        </label>

                        {/* INPUT */}
                        <input
                          className="form-control dark:bg-[#141414] dark:text-[#cacaca]"
                          type="text"
                          placeholder="Write a title"
                          ref={titleRef}
                          value={titleInput}
                          onChange={(e) => setTitle(e.target.value)}
                          style={{ flex: 1 }}
                        />

                      </div>
                    )}

                    {/*===========
                        SUBJECT
                    ==============*/}
                    {selectedOption && (
                      <div className="flex items-center mb-[8px] dark:text-white">

                        {/* TITLE */}
                        <label className="w-[55px] mr-[8px] dark:text-white">
                          Subject:
                        </label>

                        {/* INPUT */}
                        <div className="flex flex-1 items-center overflow-hidden mt-[4px]">

                          {/* ICON */}
                          <Popover
                            position='right'
                            onVisibleChange={(value) => setPopoverVisible(value)}
                            popupVisible={popoverVisible}
                            trigger='click'
                            className="bg-white dark:bg-[#141414]"
                            content={(
                              <>
                                <input
                                  className="dark:bg-[#141414] dark:text-[#cacaca]"
                                  ref={mergeTagSearchInput}
                                  type="search"
                                  onChange={(e) => setSearchValue(e.target.value)}
                                  value={searchValue}
                                  style={{
                                    padding: '8px',
                                    marginBottom: '10px',
                                    borderRadius: '5px',
                                    border: '1px solid #ccc',
                                    boxShadow: 'inset 0 1px 2px rgba(0,0,0,0.1)',
                                    width: '100%',
                                    boxSizing: 'border-box',
                                    fontSize: '16px',
                                    lineHeight: '1.5',
                                  }}
                                  placeholder="Type a label"
                                />
                                <Tree
                                  className="custom-tree"
                                  expandedKeys={expandedKeys}
                                  onExpand={setExpandedKeys}
                                  selectedKeys={[]}
                                  treeData={filteredTreeOptions}
                                  onSelect={(vals) => onSelect(vals)}
                                  style={{
                                    maxHeight: 400,
                                    overflow: 'auto',
                                  }}
                                />
                              </>
                            )}
                          >
                            <button
                              className="popover-button dark:bg-[#141414] dark:text-[#cacaca]"
                              onClick={() => setPopoverVisible(true)}
                            >
                              <svg width={20} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="dark:fill-white">
                                <path d="M345 39.1L472.8 168.4c52.4 53 52.4 138.2 0 191.2L360.8 472.9c-9.3 9.4-24.5 9.5-33.9 .2s-9.5-24.5-.2-33.9L438.6 325.9c33.9-34.3 33.9-89.4 0-123.7L310.9 72.9c-9.3-9.4-9.2-24.6 .2-33.9s24.6-9.2 33.9 .2zM0 229.5V80C0 53.5 21.5 32 48 32H197.5c17 0 33.3 6.7 45.3 18.7l168 168c25 25 25 65.5 0 90.5L277.3 442.7c-25 25-65.5 25-90.5 0l-168-168C6.7 262.7 0 246.5 0 229.5zM144 144a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z" />
                              </svg>
                              {/* <IconFont iconName='icon-merge-tags' /> */}
                            </button>
                          </Popover>

                          {/* SUBJECT INPUT */}
                          <div
                            className="form-control dark:bg-[#141414] dark:text-[#cacaca]"
                            ref={subjectRef}
                            contentEditable
                            suppressContentEditableWarning={true}
                            dangerouslySetInnerHTML={{ __html: subject }}
                            onInput={handleInput}
                            onFocus={handleFocus}
                            style={{
                              flex: 1,
                              padding: '8px',
                              whiteSpace: 'pre-wrap',
                              overflowY: 'auto',
                              minHeight: '40px',
                              maxHeight: '400px',
                              boxSizing: 'border-box',
                              wordBreak: 'break-word',
                              display: 'block',
                              resize: 'both'
                            }}
                          />

                        </div>

                      </div>
                    )}

                  </Modal>

                  {/*==============
                      ADD BUTTON
                  =================*/}
                  <div className="fixed bottom-4 right-4 z-50">
                    <button
                      className="p-0 w-12 h-12 bg-[#008000] rounded-full hover:bg-[#006400] active:shadow-lg mouse shadow transition ease-in duration-200 focus:outline-none flex items-center justify-center"
                      onClick={() => {
                        pushEvent({ event: 'Create' });
                        setVisible(true);
                      }}
                    >
                      <svg
                        viewBox="0 0 20 20"
                        enableBackground="new 0 0 20 20"
                        className="w-6 h-6 inline-block"
                      >
                        <path
                          fill="#FFFFFF"
                          d="M16,10c0,0.553-0.048,1-0.601,1H11v4.399C11,15.951,10.553,16,10,16c-0.553,0-1-0.049-1-0.601V11H4.601
                          C4.049,11,4,10.553,4,10c0-0.553,0.049-1,0.601-1H9V4.601C9,4.048,9.447,4,10,4c0.553,0,1,0.048,1,0.601V9h4.399
                          C15.952,9,16,9.447,16,10z"
                        />
                      </svg>
                    </button>

                  </div>

                  {/*============
                      CHILDREN
                  ===============*/}
                  <Content className="px-4 min-h-screen">
                    {children}
                  </Content>

                </section>

              </Stack.Item>

            </Stack>

          </section>

        </Layout>

      </Layout>

    </Layout>

  );

}