import '../../../../packages/easy-email-extensions/src/MergeTagBadgePrompt/MergeTagBadge.css';
import './index.css';
import { Button, Tree, Modal, Popover } from '@arco-design/web-react';
import { CardItem } from './components/CardItem';
import { DetailsResponse } from './interfaces/details_response.interface';
import { pushEvent } from '../../utils/pushEvent';
import { Stack } from '../../components/Stack';
import { useAppSelector } from '../../hooks/useAppSelector';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import emailBuilderApi from '../../../api/emailBuilderApi';
import Frame from '../../components/Frame';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Swal from 'sweetalert2';
import templateList from '../../store/templateList';
import templates from '../../config/templates.json';
import NoDataFoundImage from './assets/images/no-data-found.png';

interface TemplateListResponse {
  data: TemplateListData[];
  status: number;
}

interface TemplateListData {
  list_id: number;
  subject: string;
  template_id: number;
  template_name: string;
  type: string;
  module: string;
  creator_name: string;
  modifier_name: string;
  status: string;
  created_at: string;
  updated_at: string;
}

interface ListResponse {
  data: ListResponseData[];
  status: number;
}

interface ListResponseData {
  rec_id: number;
  slug: string;
  label: string;
}

interface Label {
  column_name: string;
  title: string;
}

export interface FieldsResponse {
  data: FieldsData[];
  status: number;
}

export interface FieldsData {
  label: string;
  column_name: string;
  type: string;
}

export default function Home() {

  const history = useHistory();

  const dispatch = useDispatch();
  const list = useAppSelector('templateList');

  /**=============
   * URL PARAMS
  ================*/
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const moduleSlug = queryParams.get('slug'); // SLUG PARAMETER THAT COMES BY THE URL //
  const moduleSlugLabel = queryParams.get('label'); //  LABEL PARAMETER THAT COMES BY THE URL //

  /**=========
   * STATES
  ============*/
  const [templateId, setTemplateId] = useState<number>(); // SELECTED TEMPLATE ID (THIS IS REQUIRED FOR THE EDITOR) //
  const [listId, setListId] = useState<number>(); // SELECTED LIST ID (THIS IS REQUIRED FOR THE EDITOR) //
  const [templateListData, setTemplateListData] = useState<TemplateListData[]>(); // LIST OF BACKEND TEMPLATES //
  const [visible, setVisible] = useState<boolean>(false); // TO SEE THE ADD MODAL //
  const [editTemplateVisible, setEditTemplateVisible] = useState<boolean>(false); // TO SEE THE MODAL EDIT //
  const [listData, setList] = useState<ListResponseData[]>(); // LIST OF MODULES //
  const [title, setTitle] = useState<string>(''); // TITLE INPUT VALUE //
  const [subject, setSubject] = useState<string>(''); // SUBJECT INPUT VALUE //
  const [selectedOption, setSelectedOption] = useState(''); // MODULE INPUT VALUE //
  const [searchTerm, setSearchTerm] = useState(''); // SEARCH INPUT VALUE //
  const [expandedKeys, setExpandedKeys] = useState<string[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [popoverVisible, setPopoverVisible] = useState<boolean>(false);
  const [editPopoverVisible, setEditPopoverVisible] = useState<boolean>(false);
  const [fetchLabelsData, setFetchLabelsData] = useState<Label[]>([]);
  const [openDropdown, setOpenDropdown] = useState(null);

  /**=============
   * REFERENCES
  ================*/
  const titleRef = useRef<HTMLInputElement>(null);
  const subjectRef = useRef<HTMLDivElement>(null);
  const editSubjectRef = useRef<HTMLDivElement>(null);
  const moduleRef = useRef<HTMLSelectElement>(null);
  const mergeTagSearchInput = useRef<HTMLInputElement>(null);
  const editMergeTagSearchInput = useRef<HTMLInputElement>(null);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  /**==========================================
   * THESE ARE THE FILTERS OF THE SEARCH BAR
  =============================================*/
  const filteredTemplates = templateListData?.filter(template =>
    template.template_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    template.subject.toLowerCase().includes(searchTerm.toLowerCase()) ||
    template.module.toLowerCase().includes(searchTerm.toLowerCase()) ||
    template.creator_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    template.created_at.toLowerCase().includes(searchTerm.toLowerCase()) ||
    template.updated_at.toLowerCase().includes(searchTerm.toLowerCase())
  );

  /**==================================
   * HANDLE DROPDOWN TOGGLE FUNCTION
   * @param {any} templateId
   * @returns {void}
  =====================================*/
  const handleDropdownToggle = (templateId: any): void => {
    setOpenDropdown(openDropdown === templateId ? null : templateId);
  };

  /**===========================
   * GET LABELS FUNCTION
   * @returns {Promise<void>}
  ==============================*/
  const getLabels = async (): Promise<void> => {

    try {

      const { data } = await emailBuilderApi.get<FieldsResponse>(`modules/${moduleSlug ?? 'leads'}/fields`);

      const labelFormat: Label[] = data.data.map(ele => {
        return {
          column_name: ele.column_name,
          title: ele.label
        }
      });

      setFetchLabelsData(labelFormat);

    } catch (error) {

      Swal.fire({
        customClass: 'dark:bg-[#141414]',
        icon: "error",
        title: "Oops...",
        toast: false,
        position: 'center',
        timer: 3000,
        timerProgressBar: true,
        text: "No Fields were found",
      });

      console.log(error);

    }

  }

  /**=============================
   * GET TEMPLATE LIST FUNCTION
   * @returns {Promise<void>}
  ================================*/
  const getTemplateList = async (): Promise<void> => {

    setTemplateListData([]);

    try {

      if (moduleSlug != null) {

        const { data } = await emailBuilderApi.get<TemplateListResponse>(`modules/${moduleSlug}/templates/list`);

        if (data) {

          const updatedArray = data.data.map(item => {

            let updatedSubject = item.subject;

            // FIND VARIABLES ${...} IN THE SUBJECT AND REPLACE THEM WITH THEIR CORRESPONDING TITLES //
            const regex = /\$\{(.*?)\}/g;
            let match;

            while ((match = regex.exec(updatedSubject)) !== null) {

              const variableName = match[1];

              // SEARCH FETCHLABELSDATA FOR THE TITLE CORRESPONDING TO THE COLUMN NAME //
              const label = fetchLabelsData.find(label => label.column_name === variableName);

              if (label) {
                updatedSubject = updatedSubject.replace(match[0], `[${label.title}]`);
              }

            }

            return { ...item, subject: updatedSubject };

          });

          setTemplateListData(updatedArray);

        }

      }

    } catch (error) {

      console.error('Error fetching list', error);

    }

  }

  /**===========================
   * GET LIST FUNCTION
   * @returns {Promise<void>}
  ==============================*/
  const getList = async (): Promise<void> => {

    try {

      const { data } = await emailBuilderApi.get<ListResponse>('modules/list');

      if (data) {

        setList(data.data);

      }

    } catch (error) {

      console.error('Error fetching list', error);

    }

  }

  /**=============================
   * EDIT BTN FUNCTION
   * @param {number} templateId
   * @param {number} listId
   * @returns {Promise<void>}
  ================================*/
  const editBtn = async (templateId: number, listId: number): Promise<void> => {

    getLabels();
    getList();
    const { data } = await emailBuilderApi.get<DetailsResponse>(`modules/${moduleSlug}/templates/${templateId}/show`);

    if (!data) return;

    // REGULAR EXPRESSION TO FIND ALL VARIABLES ${...} //
    const regex = /\${([^}]*)}/g;

    // REPLACE EACH OCCURRENCE OF ${LABEL} WITH <SPAN CLASS="EASY-EMAIL-MERGE-TAG" CONTENTEDITABLE="FALSE">${SELECTEDLABEL.TITLE}</SPAN>
    const replacedSubject = data.data.subject.replace(regex, (match, label) => {

      // FIND THE CORRESPONDING OBJECT IN FETCHLABELSDATA //
      const columnObj = fetchLabelsData.find(col => col.column_name === label);

      // IF OBJECT IS FOUND, REPLACE WITH SPAN //
      if (columnObj) {
        return `<input class="easy-email-merge-tag" type="button" value='${columnObj.title}'>`;
      }

      // IF NOT FOUND, RETURN THE ORIGINAL MATCH WITHOUT CHANGES //
      return match;

    });

    setTitle(data.data.name);
    setSubject(replacedSubject);
    setSelectedOption(data.data.module);

    setEditTemplateVisible(true);

    document.body.style.overflow = '';
    document.body.style.width = '';

    setTemplateId(templateId);
    setListId(listId);

  }

  /**======================
   * ON DISCARD FUNCTION
   * @returns {void}
  =========================*/
  const onDiscard = (): void => {
    setVisible(false);
    setEditTemplateVisible(false);
    setSelectedOption('');
    setTitle('');
    setSubject('');
  };

  /**===============================
   * GO TO EDIT TEMPLATE FUNCTION
   * @returns {void}
  ==================================*/
  const goToEditTemplate = (): void => {

    // SUPPOSE 'SUBJECT' IS THE ORIGINAL STRING CONTAINING THE INPUTS AND '&NBSP;' //
    let modifiedSubject = subject; // WE MAKE A COPY OF 'SUBJECT' TO MODIFY //

    // SPLIT ORIGINAL STRING USING '&NBSP;' //
    const parts = subject.split('&nbsp;');

    // ITERATE OVER PARTS TO FIND AND REPLACE INPUTS WITH 'EASY-EMAIL-MERGE-TAG' CLASS //
    parts.forEach(part => {

      if (part.includes('class="easy-email-merge-tag"')) {

        // FIND THE VALUE OF THE 'VALUE' ATTRIBUTE WITHIN THE INPUT //
        const valueMatch = /value=['"]([^'"]*)['"]/i.exec(part);

        if (valueMatch) {

          const inputValue = valueMatch[1]; // VALUE WITHIN THE 'VALUE' ATTRIBUTE OF THE INPUT //

          // FIND THE OBJECT IN FETCHLABELSDATA THAT HAS THE SAME TITLE AS THE INPUT VALUE //
          const columnObj = fetchLabelsData.find(col => col.title === inputValue);

          if (columnObj) {

            // REPLACE THE FOUND INPUT WITH THE CORRESPONDING PLACEHOLDER //
            modifiedSubject = modifiedSubject.replace(part, `\${${columnObj.column_name}}`);

          }

        }

      }

    });

    // REPLACE '&NBSP;' FOR BLANK SPACES //
    // modifiedSubject = modifiedSubject.replace(/&nbsp;/g, ' ');

    localStorage.setItem('templateInfo', JSON.stringify({ title, subject: modifiedSubject }));
    history.push(`/editor?id=${templateId}&userId=${listId}&fetch=${true}&moduleSlug=${moduleSlug}`);

  }

  /**========================
   * HANDLE INPUT FUNCTION
   * @param {any} e
   * @returns {void}
  ===========================*/
  const handleInput = (e: any): void => {
    setSubject(e.target.innerHTML); // UPDATE SUBJECT STATUS
  };

  /**========================
   * HANDLE FOCUS FUNCTION
   * @returns {void}
  ===========================*/
  const handleFocus = (): void => {
    setTimeout(setCaretToEnd, 0);  // DELAY SETTING CARET TO THE END TO ENSURE IT HAPPENS AFTER FOCUS //
  };

  /**===========================
   * SET CARET TO END FUNTION
   * @returns {void}
  ==============================*/
  const setCaretToEnd = (): void => {
    const el = subjectRef.current;
    const ele = editSubjectRef.current;
    if (el) {
      const range = document.createRange();
      const sel = window.getSelection();
      range.selectNodeContents(el);
      range.collapse(false);
      sel?.removeAllRanges();
      sel?.addRange(range);
      el.focus();
    } else if (ele) {
      const range = document.createRange();
      const sel = window.getSelection();
      range.selectNodeContents(ele);
      range.collapse(false);
      sel?.removeAllRanges();
      sel?.addRange(range);
      ele.focus();
    }
  };

  const filteredTreeOptions = useMemo(() => {

    const filterData = (data: any[], query: string) => {
      return data.filter(item => item.title.toLowerCase().includes(query.toLowerCase()));
    };

    const treeData = fetchLabelsData.map(label => ({
      key: label.column_name,
      value: label.column_name,
      title: label.title,
      children: []
    }));

    return filterData(treeData, searchValue);

  }, [fetchLabelsData, searchValue]);

  const onSelect = useCallback(

    (selectedKeys: any[]) => {

      const key = selectedKeys[0];

      // FIND THE OBJECT IN FETCHLABELSDATA THAT HAS COLUMN_NAME EQUAL TO KEY //
      const selectedLabel = fetchLabelsData.find(label => label.column_name === key);

      if (selectedLabel) {

        const updatedSubject = `${subject}&nbsp;<input class="easy-email-merge-tag" type="button" value='${selectedLabel.title}'>&nbsp;`;
        setSubject(updatedSubject.trim());

      } else {

        // HANDLE THE CASE WHERE THE CORRESPONDING LABEL IS NOT FOUND //
        console.error(`Tag for column_name not found: ${key}`);

      }

      setPopoverVisible(false);
      setEditPopoverVisible(false);

    }, [subject, fetchLabelsData]

  );

  /**==============
   * USE EFFECTS
  =================*/
  useEffect(() => {

    const fetchData = async () => {

      const { data } = await emailBuilderApi.get<FieldsResponse>(`modules/${selectedOption}/fields`);

      const labelFormat: Label[] = data.data.map(ele => {
        return {
          column_name: ele.column_name,
          title: ele.label
        }
      });

      setFetchLabelsData(labelFormat);

    };

    if (selectedOption) {
      fetchData();
    }

  }, [selectedOption]);

  useEffect(() => {
    dispatch(templateList.actions.fetch(undefined));
  }, [dispatch]);

  useEffect(() => {
    getTemplateList();
  }, [moduleSlug]);

  useEffect(() => {
    getLabels();
  }, [moduleSlug]);

  useEffect(() => {
    if (visible === true) {
      getLabels();
      getList();
      document.body.style.overflow = '';
      document.body.style.width = '';
    }
  }, [visible]);

  useEffect(() => {
    setCaretToEnd();
  }, [subject]);

  useEffect(() => {
    mergeTagSearchInput.current?.focus();
  }, [popoverVisible]);

  useEffect(() => {
    editMergeTagSearchInput.current?.focus();
  }, [editPopoverVisible]);

  useEffect(() => {

    const handleClickOutside = (event: any) => {

      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {

        setOpenDropdown(null);

      }

    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {

      document.removeEventListener('mousedown', handleClickOutside);

    };

  }, []);

  return (

    /**========
     * FRAME
    ===========*/
    <Frame
      title=''
    >

      <>

        {/*==============
            EDIT MODAL
        =================*/}
        <Modal
          className="dark:bg-[#141414]"
          title={
            <p className="dark:text-white">
              Edit template
            </p>
          }
          visible={Boolean(editTemplateVisible)}
          onOk={goToEditTemplate}
          okText='Edit template'
          cancelText='Discard'
          onCancel={onDiscard}
          style={{ zIndex: 10000 }}
          footer={
            <>
              <Button onClick={onDiscard}>Discard</Button>
              <Button type="primary" onClick={goToEditTemplate}>Edit template</Button>
            </>
          }
        >

          {/*==========
              MODULE
          =============*/}
          <div className="flex items-center mb-[8px] dark:text-white">

            {/* LABEL */}
            <label className="w-[55px] mr-[8px] dark:text-white">
              Module:
            </label>

            {/* INPUT */}
            <select
              className="form-control dark:bg-[#141414] dark:text-[#cacaca]"
              ref={moduleRef}
              value={selectedOption}
              onChange={(e) => setSelectedOption(e.target.value)}
              style={{ flex: 1 }}
            >
              <option value="" disabled>Select a module</option>
              {listData?.map(item => (
                <option key={item.rec_id} value={item.slug}>{item.label}</option>
              ))}
            </select>

          </div>

          {/*=========
              TITLE
          ============*/}
          {selectedOption && (
            <div className="flex items-center mb-[5px] dark:text-white">

              {/* LABEL */}
              <label className="w-[55px] mr-[8px] dark:text-white">
                Title:
              </label>

              {/* INPUT */}
              <input
                className="form-control dark:bg-[#141414] dark:text-[#cacaca]"
                type="text"
                placeholder="Write a title"
                ref={titleRef}
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                style={{ flex: 1 }}
              />

            </div>
          )}

          {/*===========
              SUBJECT
          ==============*/}
          {selectedOption && (
            <div className="flex items-center mb-[8px] dark:text-white">

              {/* TITLE */}
              <label className="w-[55px] mr-[8px] dark:text-white">
                Subject:
              </label>

              {/* INPUT */}
              <div className="flex flex-1 items-center overflow-hidden mt-[4px]">

                {/* ICON */}
                <Popover
                  position='right'
                  onVisibleChange={(value) => setEditPopoverVisible(value)}
                  popupVisible={editPopoverVisible}
                  trigger='click'
                  className="bg-white dark:bg-[#141414]"
                  content={(
                    <>
                      <input
                        className="dark:bg-[#141414] dark:text-[#cacaca]"
                        ref={editMergeTagSearchInput}
                        type="search"
                        onChange={(e) => setSearchValue(e.target.value)}
                        value={searchValue}
                        style={{
                          padding: '8px',
                          marginBottom: '10px',
                          borderRadius: '5px',
                          border: '1px solid #ccc',
                          boxShadow: 'inset 0 1px 2px rgba(0,0,0,0.1)',
                          width: '100%',
                          boxSizing: 'border-box',
                          fontSize: '16px',
                          lineHeight: '1.5',
                        }}
                        placeholder="Type a label"
                      />
                      <Tree
                        className="custom-tree"
                        expandedKeys={expandedKeys}
                        onExpand={setExpandedKeys}
                        selectedKeys={[]}
                        treeData={filteredTreeOptions}
                        onSelect={(vals) => onSelect(vals)}
                        style={{
                          maxHeight: 400,
                          overflow: 'auto',
                        }}
                      />
                    </>
                  )}
                >
                  <button
                    className="popover-button dark:bg-[#141414] dark:text-[#cacaca]"
                    onClick={() => setEditPopoverVisible(true)}
                  >
                    <svg width={20} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="dark:fill-white">
                      <path d="M345 39.1L472.8 168.4c52.4 53 52.4 138.2 0 191.2L360.8 472.9c-9.3 9.4-24.5 9.5-33.9 .2s-9.5-24.5-.2-33.9L438.6 325.9c33.9-34.3 33.9-89.4 0-123.7L310.9 72.9c-9.3-9.4-9.2-24.6 .2-33.9s24.6-9.2 33.9 .2zM0 229.5V80C0 53.5 21.5 32 48 32H197.5c17 0 33.3 6.7 45.3 18.7l168 168c25 25 25 65.5 0 90.5L277.3 442.7c-25 25-65.5 25-90.5 0l-168-168C6.7 262.7 0 246.5 0 229.5zM144 144a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z" />
                    </svg>
                    {/* <IconFont iconName='icon-merge-tags' /> */}
                  </button>
                </Popover>

                {/* SUBJECT INPUT */}
                <div
                  className="form-control dark:bg-[#141414] dark:text-[#cacaca]"
                  ref={editSubjectRef}
                  contentEditable
                  suppressContentEditableWarning={true}
                  dangerouslySetInnerHTML={{ __html: subject }}
                  onInput={handleInput}
                  onFocus={handleFocus}
                  style={{
                    flex: 1,
                    padding: '8px',
                    whiteSpace: 'pre-wrap',
                    overflowY: 'auto',
                    minHeight: '40px',
                    maxHeight: '400px',
                    boxSizing: 'border-box',
                    wordBreak: 'break-word',
                    display: 'block',
                    resize: 'both',
                  }}
                />

              </div>

            </div>
          )}

        </Modal>

        {/*====================================================
            IF THERE IS SLUG MODULE I SHOW THE TEMPLATE LIST
        =======================================================*/}
        {moduleSlug && (

          <>

            {/*==========================
                BACKEND TEMPLATES LIST
            =============================*/}
            <section className="relative overflow-x-auto sm:rounded-lg pr-16">

              {/*============================
                  FILTERS AND SEARCH INPUT
              ===============================*/}
              <div className="flex flex-column sm:flex-row flex-wrap space-y-4 sm:space-y-0 items-center justify-between pb-4">

                {/*=========
                    SPACE
                ============*/}
                <div></div>

                {/*================
                    INPUT SEARCH
                ===================*/}
                <label htmlFor="search" className="sr-only">Search</label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 rtl:inset-r-0 rtl:right-0 flex items-center ps-3 pointer-events-none">
                    <svg className="w-5 h-5 text-gray-500 dark:text-gray-400" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"></path></svg>
                  </div>
                  <input
                    type="search"
                    name="search"
                    id="search"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="block p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-[#363635] dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Type to filter & search..."
                  ></input>
                </div>

              </div>

              {filteredTemplates && filteredTemplates.length > 0 ? (

                /**=========
                 * SCROLL
                ============*/
                <div className="overflow-y-auto max-h-[740px]">

                  {/*=========
                      TABLE
                  ============*/}
                  <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 rounded-lg overflow-hidden">

                    {/*==============
                        TABLE HEAD
                    =================*/}
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-[#242423] dark:text-gray-400">

                      {/* TABLE ROW */}
                      <tr>

                        {/* TEMPLATE NAME */}
                        <th scope="col" className="px-6 py-3">Template Name</th>

                        {/* CREATED BY */}
                        <th scope="col" className="px-6 py-3">Created By</th>

                        {/* UPDATED AT */}
                        <th scope="col" className="px-6 py-3">Updated At</th>

                        {/* CREATED AT */}
                        <th scope="col" className="px-6 py-3">Created At</th>

                        {/* ACTIONS */}
                        <th scope="col" className="px-6 py-3">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512" className='w-5 h-5 dark:fill-white'>
                            <path d="M64 360a56 56 0 1 0 0 112 56 56 0 1 0 0-112zm0-160a56 56 0 1 0 0 112 56 56 0 1 0 0-112zM120 96A56 56 0 1 0 8 96a56 56 0 1 0 112 0z" />
                          </svg>
                        </th>

                      </tr>

                    </thead>

                    {/*==============
                        TABLE BODY
                    =================*/}
                    <tbody>

                      {filteredTemplates.map(template => (

                        // TABLE ROW //
                        <tr key={template.template_id} className="bg-white border-b dark:bg-[#363635] dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-[#4A4A48]">

                          {/* TEMPLATE NAME */}
                          <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            {template.template_name}
                          </th>

                          {/* CREATED BY */}
                          <td className="px-6 py-4">{template.creator_name}</td>

                          {/* UPDATED AT */}
                          <td className="px-6 py-4">{template.updated_at}</td>

                          {/* CREATED AT */}
                          <td className="px-6 py-4">{template.created_at}</td>

                          {/* ACTION BUTTON */}
                          <td className="px-6 py-4">

                            <div className="relative">

                              {/* BUTTON */}
                              <button
                                type="button"
                                onClick={() => handleDropdownToggle(template.template_id)}
                              >
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512" className='w-5 h-5 dark:fill-white'>
                                  <path d="M64 360a56 56 0 1 0 0 112 56 56 0 1 0 0-112zm0-160a56 56 0 1 0 0 112 56 56 0 1 0 0-112zM120 96A56 56 0 1 0 8 96a56 56 0 1 0 112 0z" />
                                </svg>
                              </button>

                              {/* DROPDOWN MENU */}
                              {openDropdown === template.template_id && (

                                <div
                                  ref={dropdownRef}
                                  className="absolute bottom-0 right-14 mt-2 w-48 dark:bg-[#141414] border border-gray-200 dark:border-gray-700 rounded shadow-lg z-50"
                                >

                                  {/* CONTENT */}
                                  <ul className="py-1 text-sm text-gray-700 dark:text-gray-200">

                                    {/* EDIT BUTTON */}
                                    <li>
                                      <button
                                        onClick={() => editBtn(template.template_id, template.list_id)}
                                        className="flex items-center px-4 py-2 bg-white hover:bg-gray-100 dark:bg-[#141414] dark:hover:bg-[#363635] w-full text-left text-sm"
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 512 512"
                                          className="w-[16px] h-[16px] dark:fill-white mb-1 mr-1"
                                        >
                                          <path d="M441 58.9L453.1 71c9.4 9.4 9.4 24.6 0 33.9L424 134.1 377.9 88 407 58.9c9.4-9.4 24.6-9.4 33.9 0zM209.8 256.2L344 121.9 390.1 168 255.8 302.2c-2.9 2.9-6.5 5-10.4 6.1l-58.5 16.7 16.7-58.5c1.1-3.9 3.2-7.5 6.1-10.4zM373.1 25L175.8 222.2c-8.7 8.7-15 19.4-18.3 31.1l-28.6 100c-2.4 8.4-.1 17.4 6.1 23.6s15.2 8.5 23.6 6.1l100-28.6c11.8-3.4 22.5-9.7 31.1-18.3L487 138.9c28.1-28.1 28.1-73.7 0-101.8L474.9 25C446.8-3.1 401.2-3.1 373.1 25zM88 64C39.4 64 0 103.4 0 152V424c0 48.6 39.4 88 88 88H360c48.6 0 88-39.4 88-88V312c0-13.3-10.7-24-24-24s-24 10.7-24 24V424c0 22.1-17.9 40-40 40H88c-22.1 0-40-17.9-40-40V152c0-22.1 17.9-40 40-40H200c13.3 0 24-10.7 24-24s-10.7-24-24-24H88z" />
                                        </svg>
                                        <span className="text-sm">Edit</span>
                                      </button>
                                    </li>

                                    {/* DELETE BUTTON */}
                                    <li>
                                      <button
                                        onClick={() => console.log('Delete', template.template_id)}
                                        className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 w-full text-left text-gray-500 dark:text-gray-400 disabled:bg-gray-200 dark:disabled:bg-[#363635] disabled:cursor-not-allowed"
                                        disabled
                                      >
                                        Delete
                                      </button>
                                    </li>

                                  </ul>

                                </div>

                              )}

                            </div>

                          </td>

                        </tr>

                      ))}

                    </tbody>

                  </table>

                </div>

              ) : (

                // NOT DATA FOUND IMAGE //
                <div className="flex justify-center items-center">
                  <img
                    src={NoDataFoundImage}
                    alt="No data available"
                    className="w-full h-[600px] object-cover rounded-2xl"
                  />
                </div>

              )}

            </section>

          </>

        )}

        {/*=========================================================================================
            IF THE SLUG MODULE IS VOID RENDER THE TEMPLATES AS AN EXAMPLE THAT THE PROJECT BRINGS
        ============================================================================================*/}
        {moduleSlug === null && (

          <section>

            {/*=========
                TITLE
            ============*/}
            <h1 className="text-3xl dark:text-white mb-3">
              System Base Templates
            </h1>

            {/*=========================
                SYSTEM BASE TEMPLATES
            ============================*/}
            <Stack>
              <div className="grid grid-cols-1 cut-sm:grid-cols-2 cut-md:grid-cols-3 cut-lg:grid-cols-4">
                {[...templates, ...list].map((item) => (
                  <div key={item.article_id} className="flex justify-center p-4">
                    <CardItem data={item} />
                  </div>
                ))}
              </div>
            </Stack>

          </section>

        )}

      </>

    </Frame>

  );

}
