import '../../../../../../packages/easy-email-extensions/src/MergeTagBadgePrompt/MergeTagBadge.css';
import { formatDate } from '../../../../../../helpers/formatDate';
import { getLoadingByKey, useLoading } from '@demo/hooks/useLoading';
import { IArticle } from '@demo/services/article';
import { Loading } from '@demo/components/loading';
import { Modal, Popover, Tree } from '@arco-design/web-react';
import { pushEvent } from '@demo/utils/pushEvent';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import emailBuilderApi from '../../../../../api/emailBuilderApi';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styles from './index.module.scss';
import Swal from 'sweetalert2';
import template from '@demo/store/template';
import templateList from '@demo/store/templateList';

interface CardItemProps {
    data: IArticle;
}

interface ListResponse {
    data: ListResponseData[];
    status: number;
}

interface ListResponseData {
    rec_id: number;
    slug: string;
    label: string;
}

interface Label {
    column_name: string;
    title: string;
}

interface FieldsResponse {
    data: FieldsData[];
    status: number;
}

interface FieldsData {
    label: string;
    column_name: string;
    type: string;
}

export function CardItem(props: CardItemProps) {

    const { data } = props;
    const dispatch = useDispatch();
    const history = useHistory();

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const moduleSlug = queryParams.get('slug'); // SLUG PARAMETER THAT COMES BY THE URL //

    const loading = useLoading([
        getLoadingByKey(template.loadings.duplicate, data.article_id),
        getLoadingByKey(template.loadings.removeById, data.article_id),
    ]);

    const onDelete = useCallback(() => {
        dispatch(
            template.actions.removeById({
                id: data.article_id,
                _actionKey: data.article_id,
                success() {
                    dispatch(templateList.actions.fetch(undefined));
                },
            })
        );
    }, [data, dispatch]);

    const onDuplicate: React.MouseEventHandler<HTMLAnchorElement> = useCallback(
        (ev) => {
            ev.preventDefault();
            dispatch(
                template.actions.duplicate({
                    article: data,
                    _actionKey: data.article_id,
                    success(id) {
                        history.push(`/editor?id=${id}`);
                    },
                })
            );
        },
        [data, dispatch, history]
    );

    /**=========
     * STATES
    ============*/
    const [visible, setVisible] = useState<boolean>(false);
    const [selectedOption, setSelectedOption] = useState('');
    const [listData, setList] = useState<ListResponseData[]>();
    const [title, setTitle] = useState<string>('');
    const [subject, setSubject] = useState<string>('');
    const [popoverVisible, setPopoverVisible] = useState<boolean>(false);
    const [expandedKeys, setExpandedKeys] = useState<string[]>([]);
    const [searchValue, setSearchValue] = useState('');
    const [fetchLabelsData, setFetchLabelsData] = useState<Label[]>([]);
    const [selectedCard, setSelectedCard] = useState(null);

    /**=============
     * REFERENCES
    ================*/
    const titleRef = useRef<HTMLInputElement>(null);
    const subjectRef = useRef<HTMLInputElement>(null);
    const moduleRef = useRef<HTMLSelectElement>(null);
    const mergeTagSearchInput = useRef<HTMLInputElement>(null);

    const filteredTreeOptions = useMemo(() => {

        const filterData = (data: any[], query: string) => {
            return data.filter(item => item.title.toLowerCase().includes(query.toLowerCase()));
        };

        const treeData = fetchLabelsData.map(label => ({
            key: label.column_name,
            value: label.column_name,
            title: label.title,
            children: []
        }));

        return filterData(treeData, searchValue);

    }, [fetchLabelsData, searchValue]);

    const handleClick = (article_id) => {
        setSelectedCard(article_id);
        pushEvent({
            event: 'Edit',
            payload: { article_id: data.article_id, title: data.title },
        });
        setVisible(true);
    };

    /**===========================
     * GET LABELS FUNCTION
     * @returns {Promise<void>}
    ==============================*/
    const getLabels = async (): Promise<void> => {

        try {

            const { data } = await emailBuilderApi.get<FieldsResponse>(`modules/${moduleSlug ?? 'leads'}/fields`);

            const labelFormat: Label[] = data.data.map(ele => {
                return {
                    column_name: ele.column_name,
                    title: ele.label
                }
            });

            setFetchLabelsData(labelFormat);

        } catch (error) {

            Swal.fire({
                customClass: 'dark:bg-[#141414]',
                icon: "error",
                title: "Oops...",
                toast: false,
                position: 'center',
                timer: 3000,
                timerProgressBar: true,
                text: "No Fields were found",
            });

            console.log(error);

        }

    }

    /**===========================
       * GET LIST FUNCTION
       * @returns {Promise<void>}
      ==============================*/
    const getList = async (): Promise<void> => {
        try {
            const { data } = await emailBuilderApi.get<ListResponse>('modules/list');
            if (data) {
                setList(data.data);
            }
        } catch (error) {
            console.error('Error fetching list', error);
        }
    }

    /**======================
     * ON DISCARD FUNCTION
     * @returns {void}
    =========================*/
    const onDiscard = (): void => {
        setVisible(false);
        setSelectedOption('');
        setTitle('');
        setSubject('');
        setSelectedCard(null);
    };

    /**========================
     * GO TO EDITOR FUNCTION
     * @returns {void}
    ===========================*/
    const goToEditor = (article_id: number, user_id: number): void => {

        if (selectedOption != '' && title != '' && subject != '') {

            // SUPPOSE 'SUBJECT' IS THE ORIGINAL STRING CONTAINING THE INPUTS AND '&NBSP;' //
            let modifiedSubject = subject; // WE MAKE A COPY OF 'SUBJECT' TO MODIFY //

            // SPLIT ORIGINAL STRING USING '&NBSP;' //
            const parts = subject.split('&nbsp;');

            // ITERATE OVER PARTS TO FIND AND REPLACE INPUTS WITH 'EASY-EMAIL-MERGE-TAG' CLASS //
            parts.forEach(part => {

                if (part.includes('class="easy-email-merge-tag"')) {

                    // FIND THE VALUE OF THE 'VALUE' ATTRIBUTE WITHIN THE INPUT //
                    const valueMatch = /value=['"]([^'"]*)['"]/i.exec(part);

                    if (valueMatch) {

                        const inputValue = valueMatch[1]; // VALUE WITHIN THE 'VALUE' ATTRIBUTE OF THE INPUT //

                        // FIND THE OBJECT IN FETCHLABELSDATA THAT HAS THE SAME TITLE AS THE INPUT VALUE //
                        const columnObj = fetchLabelsData.find(col => col.title === inputValue);

                        if (columnObj) {

                            // REPLACE THE FOUND INPUT WITH THE CORRESPONDING PLACEHOLDER //
                            modifiedSubject = modifiedSubject.replace(part, `\${${columnObj.column_name}}`);

                        }

                    }

                }

            });

            // REPLACE '&NBSP;' FOR BLANK SPACES //
            // modifiedSubject = modifiedSubject.replace(/&nbsp;/g, ' ');

            localStorage.setItem('templateInfo', JSON.stringify({ title, subject: modifiedSubject }));
            history.push(`/editor?id=${article_id}&userId=${user_id}&moduleSlug=${selectedOption}`);

        } else {

            Swal.fire({
                customClass: 'dark:bg-[#141414]',
                icon: "error",
                title: "",
                text: "You must complete all empty fields",
                position: "top",
                toast: true,
                timer: 2000,
                timerProgressBar: true,
            });

            const fields = {
                selectedOption: moduleRef.current,
                title: titleRef.current,
                subject: subjectRef.current,
            };

            for (const [key, ref] of Object.entries(fields)) {
                if (!eval(key)) {
                    ref?.focus();
                    break;
                }
            }

        }

    }

    /**========================
     * HANDLE INPUT FUNCTION
     * @param {any} e
     * @returns {void}
     ===========================*/
    const handleInput = (e: any): void => {
        setSubject(e.target.innerHTML); // UPDATE SUBJECT STATUS
    };

    /**========================
     * HANDLE FOCUS FUNCTION
     * @returns {void}
    ===========================*/
    const handleFocus = (): void => {
        setTimeout(setCaretToEnd, 0);  // DELAY SETTING CARET TO THE END TO ENSURE IT HAPPENS AFTER FOCUS //
    };

    /**===========================
     * SET CARET TO END FUNTION
     * @returns {void}
    ==============================*/
    const setCaretToEnd = (): void => {
        const el = subjectRef.current;
        if (el) {
            const range = document.createRange();
            const sel = window.getSelection();
            range.selectNodeContents(el);
            range.collapse(false);
            sel?.removeAllRanges();
            sel?.addRange(range);
            el.focus();
        }
    };

    const onSelect = useCallback(

        (selectedKeys: any[]) => {

            const key = selectedKeys[0];

            // FIND THE OBJECT IN FETCHLABELSDATA THAT HAS COLUMN_NAME EQUAL TO KEY //
            const selectedLabel = fetchLabelsData.find(label => label.column_name === key);

            if (selectedLabel) {

                const updatedSubject = `${subject}&nbsp;<input class="easy-email-merge-tag" type="button" value='${selectedLabel.title}'>&nbsp;`;
                setSubject(updatedSubject.trim());

            } else {

                // HANDLE THE CASE WHERE THE CORRESPONDING LABEL IS NOT FOUND //
                console.error(`No se encontró el label para column_name: ${key}`);

            }

            setPopoverVisible(false);

        }, [subject, fetchLabelsData]

    );

    /**==============
     * USE EFFECTS
    =================*/
    useEffect(() => {

        const fetchData = async () => {

            const { data } = await emailBuilderApi.get<FieldsResponse>(`modules/${selectedOption}/fields`);

            const labelFormat: Label[] = data.data.map(ele => {
                return {
                    column_name: ele.column_name,
                    title: ele.label
                }
            });

            setFetchLabelsData(labelFormat);

        };

        if (selectedOption) {
            fetchData();
        }

    }, [selectedOption]);

    useEffect(() => {
        if (visible === true) {
            getLabels();
            getList();
            document.body.style.overflow = '';
            document.body.style.width = '';
        }
    }, [visible]);

    useEffect(() => {
        setCaretToEnd();
    }, [subject]);

    useEffect(() => {
        mergeTagSearchInput.current?.focus();
    }, [popoverVisible]);

    return (

        <>

            {/*=============
                CARD ITEM
            ================*/}
            <div
                key={data.article_id}
                className={`relative max-w-sm border-2 rounded-xl shadow cursor-pointer group ${selectedCard === data.article_id
                    ? 'border-[#006400] bg-white dark:bg-[#141414]'
                    : 'bg-white border-gray-200 hover:border-[#006400] dark:bg-[#141414] dark:border-gray-700 dark:hover:border-[#006400]'
                    }`}
                onClick={() => handleClick(data.article_id)}
            >

                {/*========
                    ICON
                ===========*/}
                <section
                    className={`absolute top-2 right-2 flex items-center justify-center rounded-full w-9 h-9 transition duration-300 ease-in-out z-10 ${selectedCard === data.article_id
                        ? 'opacity-100 scale-110 bg-[#006400]'
                        : 'opacity-0 group-hover:opacity-100 group-hover:scale-110 bg-[#006400]'
                        }`}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        className='w-[20px] h-[20px] fill-white'
                    >
                        <path d="M200.3 81.5C210.9 61.5 231.9 48 256 48s45.1 13.5 55.7 33.5C317.1 91.7 329 96.6 340 93.2c21.6-6.6 46.1-1.4 63.1 15.7s22.3 41.5 15.7 63.1c-3.4 11 1.5 22.9 11.7 28.2c20 10.6 33.5 31.6 33.5 55.7s-13.5 45.1-33.5 55.7c-10.2 5.4-15.1 17.2-11.7 28.2c6.6 21.6 1.4 46.1-15.7 63.1s-41.5 22.3-63.1 15.7c-11-3.4-22.9 1.5-28.2 11.7c-10.6 20-31.6 33.5-55.7 33.5s-45.1-13.5-55.7-33.5c-5.4-10.2-17.2-15.1-28.2-11.7c-21.6 6.6-46.1 1.4-63.1-15.7S86.6 361.6 93.2 340c3.4-11-1.5-22.9-11.7-28.2C61.5 301.1 48 280.1 48 256s13.5-45.1 33.5-55.7C91.7 194.9 96.6 183 93.2 172c-6.6-21.6-1.4-46.1 15.7-63.1S150.4 86.6 172 93.2c11 3.4 22.9-1.5 28.2-11.7zM256 0c-35.9 0-67.8 17-88.1 43.4c-33-4.3-67.6 6.2-93 31.6s-35.9 60-31.6 93C17 188.2 0 220.1 0 256s17 67.8 43.4 88.1c-4.3 33 6.2 67.6 31.6 93s60 35.9 93 31.6C188.2 495 220.1 512 256 512s67.8-17 88.1-43.4c33 4.3 67.6-6.2 93-31.6s35.9-60 31.6-93C495 323.8 512 291.9 512 256s-17-67.8-43.4-88.1c4.3-33-6.2-67.6-31.6-93s-60-35.9-93-31.6C323.8 17 291.9 0 256 0zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z" />
                    </svg>
                </section>

                {/*=========
                    IMAGE
                ============*/}
                <section className="dark:bg-[#141414]">
                    <div
                        className="rounded-xl w-72 h-52 bg-cover bg-no-repeat bg-center items-center justify-center mt-[20px]"
                        style={{ backgroundImage: `url(${data.picture})`, backgroundSize: '80%' }}
                    ></div>
                </section>

                {/*=============
                    CARD BODY
                ================*/}
                <section className="dark:bg-[#363635] dark:text-white rounded-b-[12px]">
                    <div className="p-2">
                        <div className={styles.title}>
                            {data.title}
                        </div>
                        <article className="mb-1 dark:text-[#7c7c7c]">
                            <div className={styles.title}>
                                {formatDate(data.created_at)}
                            </div>
                        </article>
                        <small className="inline-flex items-center px-3 py-0.5 rounded-full font-medium bg-[#006400] text-white">
                            Example
                        </small>
                    </div>
                </section>
            </div>

            <Modal
                className="dark:bg-[#141414]"
                title={
                    <p className="dark:text-white">
                        Create a new template
                    </p>
                }
                visible={Boolean(visible)}
                onOk={() => goToEditor(data.article_id, data.user_id)}
                okText='New template'
                cancelText='Discard'
                onCancel={onDiscard}
                style={{ zIndex: 10000 }}
            >

                {/*==========
                    MODULE
                =============*/}
                <div className="flex items-center mb-[8px] dark:text-white">

                    {/* LABEL */}
                    <label className="w-[55px] mr-[8px] dark:text-white">
                        Module:
                    </label>

                    {/* INPUT */}
                    <select
                        className="form-control dark:bg-[#141414] dark:text-[#cacaca]"
                        ref={moduleRef}
                        value={selectedOption}
                        onChange={(e) => setSelectedOption(e.target.value)}
                        style={{ flex: 1 }}
                    >
                        <option value="" disabled>Select a module</option>
                        {listData?.map(item => (
                            <option key={item.rec_id} value={item.slug}>{item.label}</option>
                        ))}
                    </select>

                </div>

                {/*=========
                    TITLE
                ============*/}
                {selectedOption && (
                    <div className="flex items-center mb-[5px] dark:text-white">

                        {/* LABEL */}
                        <label className="w-[55px] mr-[8px] dark:text-white">
                            Title:
                        </label>

                        {/* INPUT */}
                        <input
                            className="form-control dark:bg-[#141414] dark:text-[#cacaca]"
                            type="text"
                            placeholder="Write a title"
                            ref={titleRef}
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            style={{ flex: 1 }}
                        />

                    </div>
                )}

                {/*===========
                    SUBJECT
                ==============*/}
                {selectedOption && (
                    <div className="flex items-center mb-[8px] dark:text-white">

                        {/* TITLE */}
                        <label className="w-[55px] mr-[8px] dark:text-white">
                            Subject:
                        </label>

                        {/* INPUT */}
                        <div className="flex flex-1 items-center overflow-hidden mt-[4px]">

                            {/* ICON */}
                            <Popover
                                position='right'
                                onVisibleChange={(value) => setPopoverVisible(value)}
                                popupVisible={popoverVisible}
                                trigger='click'
                                className="bg-white dark:bg-[#141414]"
                                content={(
                                    <>
                                        <input
                                            className="dark:bg-[#141414] dark:text-[#cacaca]"
                                            ref={mergeTagSearchInput}
                                            type="search"
                                            onChange={(e) => setSearchValue(e.target.value)}
                                            value={searchValue}
                                            style={{
                                                padding: '8px',
                                                marginBottom: '10px',
                                                borderRadius: '5px',
                                                border: '1px solid #ccc',
                                                boxShadow: 'inset 0 1px 2px rgba(0,0,0,0.1)',
                                                width: '100%',
                                                boxSizing: 'border-box',
                                                fontSize: '16px',
                                                lineHeight: '1.5',
                                            }}
                                            placeholder="Type a label"
                                        />
                                        <Tree
                                            className="custom-tree"
                                            expandedKeys={expandedKeys}
                                            onExpand={setExpandedKeys}
                                            selectedKeys={[]}
                                            treeData={filteredTreeOptions}
                                            onSelect={(vals) => onSelect(vals)}
                                            style={{
                                                maxHeight: 400,
                                                overflow: 'auto',
                                            }}
                                        />
                                    </>
                                )}
                            >
                                <button
                                    className="popover-button dark:bg-[#141414] dark:text-[#cacaca]"
                                    onClick={() => setPopoverVisible(true)}
                                >
                                    <svg width={20} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="dark:fill-white">
                                        <path d="M345 39.1L472.8 168.4c52.4 53 52.4 138.2 0 191.2L360.8 472.9c-9.3 9.4-24.5 9.5-33.9 .2s-9.5-24.5-.2-33.9L438.6 325.9c33.9-34.3 33.9-89.4 0-123.7L310.9 72.9c-9.3-9.4-9.2-24.6 .2-33.9s24.6-9.2 33.9 .2zM0 229.5V80C0 53.5 21.5 32 48 32H197.5c17 0 33.3 6.7 45.3 18.7l168 168c25 25 25 65.5 0 90.5L277.3 442.7c-25 25-65.5 25-90.5 0l-168-168C6.7 262.7 0 246.5 0 229.5zM144 144a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z" />
                                    </svg>
                                    {/* <IconFont iconName='icon-merge-tags' /> */}
                                </button>
                            </Popover>

                            {/* SUBJECT INPUT */}
                            <div
                                className="form-control dark:bg-[#141414] dark:text-[#cacaca]"
                                ref={subjectRef}
                                contentEditable
                                suppressContentEditableWarning={true}
                                dangerouslySetInnerHTML={{ __html: subject }}
                                onInput={handleInput}
                                onFocus={handleFocus}
                                style={{
                                    flex: 1,
                                    padding: '8px',
                                    whiteSpace: 'pre-wrap',
                                    overflowY: 'auto',
                                    minHeight: '40px',
                                    maxHeight: '400px',
                                    boxSizing: 'border-box',
                                    wordBreak: 'break-word',
                                    display: 'block',
                                    resize: 'both',
                                }}
                            />

                        </div>

                    </div>
                )}

            </Modal>

        </>

    );

}
