import './styles/common.scss';
import { createBrowserHistory } from 'history';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import Home from './pages/Home';
import Login from './pages/Login';
import Page from './components/Page';
import React, { Suspense, useState, useEffect } from 'react';
import store from './store';
import { AuthProvider, useAuth } from './contexts/AuthContext';

const Editor = React.lazy(() => import('./pages/Editor'));

const history = createBrowserHistory();

function PrivateRoute({ component: Component, ...rest }: any) {
  const { isAuthenticated } = useAuth();
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  );
}

const Loading = () => {

  /**=========
   * STATES
  ============*/
  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {

    const darkModeSetting = localStorage.getItem('darkMode');

    if (darkModeSetting) {

      setDarkMode(darkModeSetting === 'true');

    }

  }, []);

  return (

    <div
      style={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: darkMode ? '#141414' : 'white',
      }}
    >

      <p style={{ fontSize: 24, color: darkMode ? 'rgba(255, 255, 255, 0.65)' : 'rgba(0, 0, 0, 0.65)' }}>
        Please wait a moment.
      </p>

    </div>

  );
  
};

function App() {
  return (
    <Provider store={store}>
      <AuthProvider>
        <Page>
          <Suspense fallback={<Loading />}>
            <Router history={history}>
              <Switch>
                <Route path="/login" component={Login} />
                <PrivateRoute path="/" exact component={Home} />
                <PrivateRoute path="/editor" component={Editor} />
                <Redirect to="/login" />
              </Switch>
            </Router>
          </Suspense>
        </Page>
      </AuthProvider>
    </Provider>
  );
}

export default App;